import { store } from '@/store/store';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { ListaCompraDto } from '@/shared/dtos/lista-compra-dto';
import { DietaDto } from '@/shared/dtos/dieta-dto';

@Module({
    namespaced: true,
    name: 'dieta_visualizacionModule',
    store,
    dynamic: true
})
class dieta_visualizacionModule extends VuexModule {
    public lista_compra: ListaCompraDto[] = [];
    public dietas:DietaDto[]=[];
    public dieta_visualizacions: any[][] = [];

    @Action({ commit: 'onListaDeLaCompra' })
    public async getListaDeLaCompraFromId(id: number) {
        return await ssmHttpService.get(API.dieta + '/lista_compra/' + id);
    }
    @Action({ commit: 'onListaDeLaCompra' })
    public async getLastListaDeLaCompra() {
        return await ssmHttpService.get(API.dieta + '/lista_compra/-1');
    }
    @Action({ commit: 'onGetDietas' })
    public async getDietas() {
        return await ssmHttpService.get(API.dieta + '/dietas_app_paciente/');
    }
    @Action({ commit: 'onGetDieta' })
    public async getDieta(id:number) {
        return await ssmHttpService.get(API.dieta + '/dieta_invertido/'+id);
    }

    @Mutation
    public onListaDeLaCompra(res: string[]) {
        this.lista_compra = res.map((x) => { let item = new ListaCompraDto(); item.articulo = x; item.comprado = false; return item });
    }

    @Mutation
    public onGetDietas(res: DietaDto[]) {
        this.dietas = res ? res.map((x) => new DietaDto(x)) : [];
    }

    @Mutation
    public onGetDieta(res: any) {
        this.dieta_visualizacions = res;
    }



}
export default getModule(dieta_visualizacionModule);